@if( !noWardsAvailable){

{{ 'WardSelectorModalNoWardAvailable' | translate }}
} @else {

{{ 'WardSelectorModalText' | translate }}

<div class="wrapper">
  <cw-list clickable="true">
    @for( site of sites; track site.id){ @for(unit of site.units; track unit.id){

    <cw-list-item
      [itemTitle]="unit.name"
      [subtitle]="site.name"
      (click)="selected({ unitId: unit.id, siteId: site.id })"
    >
    </cw-list-item>
    } }
  </cw-list>
</div>
}
