import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CwAvatar, CwIcon, CwMenu, CwMenuItem } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, map, of, switchMap, take } from 'rxjs';
import { CustomShadowCssDirective } from '~/app/directives/CustomShadowCss';
import { LastContext, Site, User } from '~/app/models/User';
import { AuthService } from '~/app/services/auth.service';
import { UserService } from '~/app/services/user.service';

@Component({
  selector: 'gplus-user',
  standalone: true,
  imports: [CommonModule, CwAvatar, CwMenu, CwMenuItem, CwIcon, TranslateModule, CustomShadowCssDirective],
  templateUrl: './user.component.html',
  styleUrl: './user.component.scss'
})
export class UserComponent {
  public profile: User;
  public image;

  public sites: Array<Site>;

  constructor(
    private readonly authService: AuthService,
    public readonly userService: UserService,
    private readonly http: HttpClient,
    private readonly sanitizer: DomSanitizer,
    private readonly router: Router
  ) {
    this.userService
      .getMe()
      .pipe(
        take(1),
        switchMap((me) => {
          this.profile = me;
          this.sites = me.sites;
          return this.getImage(this.profile.avatarUrl);
        })
      )
      .subscribe((image) => {
        this.image = image;
      });
  }

  private getImage(url) {
    return this.http.get(url, { observe: 'response', responseType: 'blob' }).pipe(
      catchError(() => {
        return of(null);
      }),
      map((response) => {
        if (!response) {
          return null;
        }
        const objectURL = URL.createObjectURL(response.body);
        const image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        return image;
      })
    );
  }

  public logout() {
    this.authService.logout();
  }

  public selectedContext(context: LastContext): void {
    this.userService.setContext(context).subscribe(() => {
      this.router.navigate(['/home']).then(() => {
        this.reloadPage();
      });
    });
  }

  // we make this public so we can spy on it in unit test
  public async reloadPage() {
    window.location.reload();
  }
}
