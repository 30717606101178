import { NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild, DestroyRef, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormsModule } from '@angular/forms';
import { CwDropdown, CwDropdownItem } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { labelType, PrintLabelPipe } from '~/app/pipes/PrintLabel';

interface DropdownItem {
  id: string;
  attrVef?: string;
  disabled?: boolean;
  group?: string;
}
@Component({
  selector: 'gplus-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  standalone: true,
  imports: [CwDropdown, CwDropdownItem, PrintLabelPipe, FormsModule, NgTemplateOutlet, TranslateModule]
})
export class DropdownComponent implements OnInit, OnChanges {
  @Input() control: AbstractControl;
  @Input() data: Array<DropdownItem>;
  @Input() multiple: boolean;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() attrVef: string;
  @Input() validations: Record<string, any> = {};
  @Input() labelType: labelType = 'default';
  @Input() loading: boolean;

  @Input() label: string;

  @ContentChild('itemTemplate') itemTemplate: NgTemplateOutlet;

  public error: string;
  public inputTextValue: any | Array<any>;
  public groups: Array<{ items: Array<DropdownItem>; key: string }>;

  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    if (this.control) {
      // Subscribe to value changes
      this.control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        // if the control value is patch or clear we can update the input text value
        this.inputTextValue = this.findValues(this.control?.value);

        this.error = Object.keys(this.validations).reduce((err, key) => {
          return err || (this.control.hasError(key) ? this.validations[key] : null);
        }, null);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.groups = this.groupValues(this.data);
      this.inputTextValue = this.findValues(this.control?.value);
    }
  }

  private groupValues(values: Array<DropdownItem>): Array<{ items: Array<DropdownItem>; key: string }> {
    const groupMap = values?.reduce<{ [key: string]: Array<DropdownItem> }>(
      (map, d) => {
        if (!d.group) {
          // we add a special key to the map to store non-grouped items
          map._nonGrouped.push(d);
          return map;
        }

        map[d.group] = map[d.group] || [];
        map[d.group].push(d);
        return map;
      },
      { _nonGrouped: [] }
    );

    if (!groupMap) {
      return [];
    }

    return (
      Object.keys(groupMap)
        .map((key) => ({ items: groupMap[key], key }))
        // we sort to have the non-grouped items at the beginning
        .toSorted((a, b) => a.key.localeCompare(b.key))
    );
  }

  /**
   *  Find the values in the data array that match the control value
   * @param controlValue
   * @returns
   */
  private findValues(controlValue: { id: string } | Array<{ id: string }>): Array<any> | any {
    if (!controlValue) {
      return this.multiple ? [] : null;
    }

    if (Array.isArray(controlValue)) {
      return this.data?.filter((d) => (controlValue as Array<{ id: string }>)?.findIndex((v) => v.id === d.id) !== -1);
    } else {
      return this.data?.find((d) => d.id === (controlValue as { id: string }).id);
    }
  }

  /**
   *  Handle the selection of an item
   * @param selection
   * @returns
   */
  public selected(selection: Record<string, unknown>) {
    if (!this.control) {
      return;
    }

    this.control.setValue(selection);
    this.control.markAsDirty();
  }
}
