import { DestroyRef, Directive, ElementRef, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessor } from './ValueAccessor';

@Directive({
  selector: 'cw-textarea',
  host: {
    '(valueChange)': 'handleChangeEvent($event.detail)'
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextAreaValueAccessor,
      multi: true
    }
  ],
  standalone: true
})
export class TextAreaValueAccessor extends ValueAccessor {
  destroyRef = inject(DestroyRef);
  constructor(protected override el: ElementRef) {
    super(el);
  }
}
