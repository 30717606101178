import { inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TherapyJobStatus } from '../enums/TherapyJobStatus';
import { SseEventMessageKey, SseEventType, SseInfusion, SsePairing, SseState } from '../models/sse-event';
import { WsEventsService } from '../services/websocket.service';
import { TherapyJobStore } from '../store/job.store';
import { NotificationsStore } from '../store/notification.store';

export class EventManager {
  public jobsStore = inject(TherapyJobStore);
  public notificationStore = inject(NotificationsStore);
  public event: SsePairing;

  constructor(protected readonly ws: WsEventsService) {
    this.ws
      .subscribeToWsEvents()
      .pipe(takeUntilDestroyed())
      .subscribe((sseEvent: SseInfusion | SsePairing | SseState) => {
        if (!sseEvent || !sseEvent?.therapyJobId) {
          return;
        }

        if (sseEvent.type === SseEventType.JOB_UPDATE) {
          const infusionEvent: SseInfusion = sseEvent as SseInfusion;
          this.jobsStore.patchJob(
            { latestEvent: infusionEvent, event: { type: SseEventType.JOB_UPDATE, timestamp: infusionEvent.ts } },
            infusionEvent.therapyJobId
          );
        }

        if (sseEvent.type === SseEventType.JOB_START) {
          const event: SseInfusion = sseEvent as SseInfusion;
          this.jobsStore.patchJob(
            { status: TherapyJobStatus.RUNNING, event: { type: SseEventType.JOB_UPDATE, timestamp: event.ts } },
            event.therapyJobId
          );
        }

        if (sseEvent.type === SseEventType.JOB_FINISH) {
          const event: SseInfusion = sseEvent as SseInfusion;
          this.jobsStore.patchJob(
            { status: TherapyJobStatus.COMPLETED, event: { type: SseEventType.JOB_UPDATE, timestamp: event.ts } },
            event.therapyJobId
          );
        }

        if (sseEvent.type === SseEventType.PAIRING) {
          const pairingEvent: SsePairing = sseEvent as SsePairing;
          this.event = pairingEvent;

          this.jobsStore.patchJob(
            {
              monitorDeviceId: pairingEvent.deviceId,
              pairingState: pairingEvent.pairingState,
              messageKey: pairingEvent?.messageKey as SseEventMessageKey,
              event: { type: SseEventType.PAIRING, timestamp: pairingEvent.ts }
            },
            pairingEvent.therapyJobId
          );

          this.notificationStore.setUnpairOnRunning(sseEvent.therapyJobId);
        }

        if (sseEvent.type === SseEventType.JOB_STATE) {
          const stateEvent: SseState = sseEvent as SseState;

          this.jobsStore.patchJob(
            {
              dripChamberDetached: stateEvent.dripChamberDetached,
              event: { type: SseEventType.JOB_STATE, timestamp: stateEvent.ts }
            },
            stateEvent.therapyJobId
          );

          this.notificationStore.setDripChamberNotification(sseEvent.therapyJobId);
        }
      });
  }
}
