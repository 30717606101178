<gplus-filters></gplus-filters>

<!-- Connections status -->
@if(ws.alive() !== null){
<ng-container>
  <div class="system-status" [ngClass]="ws.alive() && network.online() ? 'UP' : 'DOWN'">
    <span>{{ (ws.alive() && network.online() ? 'Connected' : 'Disconnected') | translate }}</span>
  </div>
</ng-container>

}

<cw-menu class="menu">
  <cw-button
    [attr.data-vef]="'button-create-therapy'"
    slot="trigger"
    class="create-button"
    button-color="accent"
    type="button"
    as="button"
    variant="filled"
  >
    {{ 'AddJobButton' | translate }}
    <cw-icon class="menu-trigger" icon="arrow_drop_down" filled="true"></cw-icon>
  </cw-button>

  <cw-menu-item [attr.data-vef]="'button-create-therapy-fromPrn'" menu-type="result" routerLink="/prn">
    <span>{{ 'AddJobOnDemand' | translate }}</span>
  </cw-menu-item>
  <cw-menu-item [attr.data-vef]="'button-create-therapy-option-manual'" menu-type="result" routerLink="/detail">
    <span>{{ 'AddJobManual' | translate }}</span>
  </cw-menu-item>
</cw-menu>
