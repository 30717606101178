import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { NotificationLevel } from '../enums/NotificationLevel';
import { Prescription } from '../models/prescription';
import { EnvironmentService } from './environment.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class PrescriptionService {
  private prescriptions: Array<Prescription>;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly env: EnvironmentService,
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService
  ) {}
  public getOnDemandPrescriptions(): Observable<Array<Prescription>> {
    return this.httpClient.get<Array<Prescription>>(`${this.env.getBaseRestUrl()}/prescription/type/ondemand`).pipe(
      tap((prescriptions) => {
        this.prescriptions = prescriptions;
      }),
      catchError((err) => {
        console.warn('Error requesting PRN prescriptions', err);

        this.toastService.show({
          message: this.translateService.instant('ErrorGeneric'),
          type: NotificationLevel.ERROR,
          enableCloseButton: true,
          delay: 5_000
        });

        return of([]);
      })
    );
  }

  public getOnDemandPrescription(id: string): Observable<Prescription> {
    if (!this.prescriptions) {
      return this.getOnDemandPrescriptions().pipe(map((prescriptions) => prescriptions.find((p) => p.id === id)));
    }
    return of(this.prescriptions?.find((p) => p.id === id));
  }
}
