import { Pipe, PipeTransform } from '@angular/core';
import { splitWords } from '../utils/splitWords';

@Pipe({
  standalone: true,
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  transform(value: string, highlight: string) {
    if (!highlight) {
      return value;
    }

    const words = splitWords(highlight);
    const pattern = `(${words.join('|')})`;
    const regex = new RegExp(pattern, 'gi');

    return value.replaceAll(regex, `<span class="highlight">$1</span>`);
  }
}
