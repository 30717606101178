@if(multiple){
<!-- In CwDropdown the property "multiple" cannot be set programmatically -->
<cw-dropdown
  [attr.data-vef]="attrVef"
  [value]="inputTextValue"
  [placeholder]="placeholder"
  [label]="label"
  [assistiveText]="error"
  [status]="error ? 'error' : null"
  [required]="required"
  [readonly]="readonly"
  [disabled]="disabled"
  (valueChanges)="selected($event.detail)"
  multiple="true"
>
  <ng-container *ngTemplateOutlet="options"></ng-container>
</cw-dropdown>
} @else {

<cw-dropdown
  [attr.data-vef]="attrVef"
  [value]="inputTextValue"
  [placeholder]="placeholder"
  [label]="label"
  [assistiveText]="error"
  [status]="error ? 'error' : null"
  [required]="required"
  [readonly]="readonly"
  [disabled]="disabled"
  (valueChanges)="selected($event.detail)"
>
  <ng-container *ngTemplateOutlet="options"></ng-container>
</cw-dropdown>
}

<ng-template #options>
  @if (loading) {
  <cw-dropdown-item [disabled]="true" [value]="null" [label]="'Loading' | translate"> </cw-dropdown-item>
  } @else {

  <!-- We iterate through the groups -->
  @for(group of groups; track group){

  <!-- For not grouped items  -->
  @if(group.key === '_nonGrouped'){

  <!-- We render the items -->
  @for(item of group.items; track item){
  <ng-container *ngTemplateOutlet="itemTemp; context: { item }"></ng-container>

  } }

  <!-- Else we render the groups -->
  @else {
  <cw-dropdown-group [label]="group.key | translate">
    <!-- We render items -->
    @for(item of group.items; track item){
    <ng-container *ngTemplateOutlet="itemTemp; context: { item }"></ng-container>

    }
  </cw-dropdown-group>

  } } }
</ng-template>

<ng-template #itemTemp let-item="item">
  <!-- 
    Note: If cw-dropdown item has something as a child, does not show the label 
    the same happens when we use @if/*ngIf inside the cw-dropdown-item tag
    for that, we need to check if the item has a template to render it with a ng-container
  
  -->

  @if(itemTemplate){
  <cw-dropdown-item
    [disabled]="item.disabled"
    [attr.data-vef]="item.attrVef"
    [value]="item"
    [label]="item | printLabel : labelType"
  >
    <ng-container *ngTemplateOutlet="itemTemplate; context: { item }"></ng-container>
  </cw-dropdown-item>
  } @else {
  <cw-dropdown-item
    [disabled]="item.disabled"
    [attr.data-vef]="item.attrVef"
    [value]="item"
    [label]="item | printLabel : labelType"
  >
  </cw-dropdown-item>
  }
</ng-template>
