import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, catchError, interval, map, of, startWith, switchMap, takeUntil } from 'rxjs';
import { NotificationLevel } from '../enums/NotificationLevel';
import { ToastService } from './toast.service';

export enum Status {
  UP = 'UP',
  DOWN = 'DOWN',
  ERROR = 'ERROR'
}

const SNACKBAR_DISCONNECTION_ID = 'connectionLostMessage';

@Injectable({
  providedIn: 'root'
})
export class StatusService {
  private destroy: Subject<void> = new Subject();
  public status: {
    id: Status;
    translationKey: string;
  };

  constructor(
    private readonly httpClient: HttpClient,
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService
  ) {}

  /**
   * Commented-out Wait until backend add to the health check endpoint all the services and database
   */
  private initPolling() {
    // Polling
    interval(10000)
      .pipe(
        takeUntil(this.destroy),
        startWith(Status.UP),
        switchMap(() => {
          return this.httpClient.get('q/health/live').pipe(
            map((res: any) => {
              if (res.status === Status.DOWN) {
                console.error('System is DOWN!');
              }

              return res?.status;
            }),
            catchError((err) => {
              console.error(`Error coming from 'q/health/live'`, err);

              return of(Status.ERROR);
            })
          );
        })
      )
      .subscribe((res) => {
        if (res === Status.ERROR || res === Status.DOWN) {
          this.status = {
            id: res,
            translationKey: 'SystemStatusDisconnected'
          };

          this.showErrorSnackbar();
          console.error(`Error coming from 'q/health/live'`);
        } else {
          this.status = {
            id: res,
            translationKey: 'SystemStatusConnected'
          };
        }
      });
  }

  public destroyPolling() {
    this.destroy.next();
    this.destroy.complete();
  }

  private showErrorSnackbar() {
    this.toastService.show({
      message: this.translateService.instant('ConnectionLostMessage'),
      type: NotificationLevel.ERROR,
      enableCloseButton: true,
      delay: 5_000
    });
  }
}
