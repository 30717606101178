import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, of, shareReplay } from 'rxjs';
import { NotificationLevel } from '../enums/NotificationLevel';
import { MonitorDevice } from '../models/MonitorDevice';
import { ResponseError, ResponseErrorExtended } from '../models/ResponseError';
import { ResponsePair } from '../models/ResponsePair';
import { EnvironmentService } from './environment.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class MonitorDeviceService {
  private activeDevices: Observable<Array<MonitorDevice> | ResponseError>;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly env: EnvironmentService,
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService
  ) {}

  public getActive(id: string, cached = false): Observable<Array<MonitorDevice> | ResponseErrorExtended> {
    let params = new HttpParams().set('therapyJobId', id);
    const request$ = this.httpClient
      .get<Array<MonitorDevice> | ResponseError>(`${this.env.getBaseRestUrl()}/device/active`, {
        params: params
      })
      .pipe(
        catchError((e) => {
          console.error('Error requesting active devices', e);
          return of({ messageKey: e?.error?.messageKey || 'ErrorGeneric', status: e.status } as ResponseError);
        })
      );

    if (!cached) {
      // we clean the cache
      this.activeDevices = null;
      return request$;
    }

    if (!this.activeDevices) {
      this.activeDevices = request$.pipe(shareReplay());
    }

    return this.activeDevices;
  }

  public pair(therapyJobId: string, monitorDeviceId?: string): Observable<void | ResponseErrorExtended> {
    let params;
    if (monitorDeviceId) {
      params = new HttpParams().set('therapyJobId', therapyJobId).set('deviceId', monitorDeviceId);
    } else {
      params = new HttpParams().set('therapyJobId', therapyJobId);
    }

    return this.httpClient.get<void | ResponseError>(`${this.env.getBaseRestUrl()}/device/pair`, {
      params: params
    });
  }

  public cancel(id: string): Observable<ResponsePair | ResponseErrorExtended> {
    return this.httpClient
      .get<ResponsePair | ResponseError>(`${this.env.getBaseRestUrl()}/device/${id}/cancelpairing`)
      .pipe(
        catchError((e) => {
          console.error('Error canceling pairing', e);
          this.showErrorMessage(e?.error?.messageKey || this.translateService.instant('ErrorGeneric'));
          return of({
            messageKey: e?.error?.messageKey || 'ErrorGeneric'
          } as ResponseErrorExtended);
        })
      );
  }

  private showErrorMessage(message: string) {
    this.toastService.show({
      message,
      type: NotificationLevel.ERROR,
      enableCloseButton: true,
      delay: 5_000
    });
  }
}
