import { AfterViewInit, Component, HostListener, inject, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { CwNavigationRail, CwNavigationRailItem } from '@bbraun/cortex-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map, switchMap, take } from 'rxjs';
import { HeaderComponent } from './components/header/header.component';
import { HeartBeatComponent } from './components/heartbeat/heart-beat.component';
import { MobileDrawerComponent } from './components/mobile-drawer/mobile-drawer.component';
import { ModalContainerComponent } from './components/modals/modal-container/modal-container.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import { SidebarContainerComponent } from './components/sidebar-container/sidebar-container.component';
import { ToastContainerComponent } from './components/toast-container/toast-container.component';
import { AuthService } from './services/auth.service';
import { DialogService } from './services/dialog.service';
import { HeaderService } from './services/header.service';
import { OverlayService } from './services/overlay.service';
import { SidebarService } from './services/sidebar.service';
import { StatusService } from './services/status.service';
import { ToastService } from './services/toast.service';
import { UserService } from './services/user.service';
import { WsEventsService } from './services/websocket.service';
import { TherapyJobStore } from './store/job.store';
import { NotificationsStore } from './store/notification.store';

@Component({
  selector: 'gplus-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    RouterModule,
    TranslateModule,
    CwNavigationRail,
    CwNavigationRailItem,
    ModalContainerComponent,
    HeaderComponent,
    OverlayComponent,
    MobileDrawerComponent,
    SidebarContainerComponent,
    HeartBeatComponent,
    ToastContainerComponent
  ]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  public loginDisplay: boolean;

  public jobsStore = inject(TherapyJobStore);
  public notificationsStore = inject(NotificationsStore);

  @HostListener('window:resize')
  onResize() {
    this.headerService.setMobileMenuBtn(false);
  }

  @HostListener('window:beforeunload')
  beforeUnload() {
    this.wsEventsService.close();
  }

  constructor(
    public readonly dialogService: DialogService,
    public readonly overlayService: OverlayService,
    public readonly sidebarService: SidebarService,
    public readonly toastService: ToastService,
    public readonly headerService: HeaderService,

    // Private
    private readonly statusService: StatusService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly wsEventsService: WsEventsService,
    private readonly userService: UserService
  ) {
    this.translateService.setDefaultLang('en');

    const browserLanguage = this.translateService.getBrowserLang();
    if (browserLanguage && browserLanguage !== 'en') {
      this.translateService
        .use(browserLanguage)
        .pipe(take(1))
        .subscribe(() => {
          console.info('Setting language to:', browserLanguage);
        });
    }

    this.router.events.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.headerService.setMobileMenuBtn(false);
      }
    });
  }

  ngOnInit(): void {
    this.authService.configureAuth();

    this.authService.isLogged
      .pipe(switchMap((isLogged) => this.userService.getMe().pipe(map((me) => ({ me, isLogged })))))
      .pipe(take(1))
      .subscribe(({ me, isLogged }) => {
        this.loginDisplay = isLogged;
        if (isLogged) {
          this.wsEventsService.connect(me, 'After login');
          this.loadEntities();
        }
      });
  }

  ngAfterViewInit(): void {
    this.iniDialog();
  }

  ngOnDestroy(): void {
    this.statusService.destroyPolling();
  }

  private loadEntities() {
    this.jobsStore.loadAll().then(() => {
      console.log('Therapy Jobs Loaded');
      // we update notifications
      this.notificationsStore.setAll();
    });
  }

  private iniDialog() {
    const dialog = document.querySelector('cw-dialog');

    if (!dialog) {
      return;
    }
    dialog.addEventListener('dialogClosed', () => {
      this.dialogService.close();
    });
  }

  public linkClicked() {
    this.headerService.setMobileMenuBtn(false);
  }
}
