import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { switchMap, take } from 'rxjs';
import { LastContext } from '../models/User';
import { UserService } from '../services/user.service';

export const TEMP_SITE_ID = '8395aadc-a4b2-471e-bc52-58792c247bb9';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly userService: UserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.endsWith('me') || req.url.includes('i18n') || req.url.includes('myprofile')) {
      return next.handle(req);
    }

    return this.userService.getContext().pipe(
      take(1),
      switchMap((context: LastContext) => next.handle(this.getAuthReq(context, req)))
    );
  }

  private getAuthReq(context: LastContext, req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      headers: req.headers.set('BB-Site-Id', context.siteId).set('BB-Unit-Id', context.unitId)
    });
  }
}
