@if(toast && toast.open ){
<gplus-snackbar
  @fadeIn
  class="gplus-snackbar"
  [ngClass]="toast.type"
  [type]="toast.type"
  [icon]="iconsMap[toast.type]"
  [text]="toast.message"
  [closeable]="toast.enableCloseButton"
  (close)="close()"
>
</gplus-snackbar>
}
