import { AbstractControl } from '@angular/forms';
import { Observable, debounceTime, map, startWith } from 'rxjs';
import { splitWords } from './splitWords';

export const change: (
  control: AbstractControl,
  array: Array<any>,
  valueProp?: string | Array<string>
) => Observable<Array<any>> = (control, array, valueProp) => {
  return control.valueChanges.pipe(
    startWith(''),
    debounceTime(100),
    map((value) => {
      if (!value || typeof value !== 'string') {
        return array;
      }
      if (valueProp) {
        if (Array.isArray(valueProp)) {
          return array.filter((item) => {
            return valueProp.some((prop) => matchWords(value, item[prop]));
          });
        } else {
          return array.filter((item) => matchWords(value, item[valueProp]));
        }
      }

      return array.filter((item) => matchWords(value, item));
    })
  );
};

/**
 * Checks if any of the words in the `searchString` are present in the `targetString`.
 *
 * @param searchString - The string containing words to match.
 * @param targetString - The string to search for matches.
 * @returns `true` if any words from `searchString` are found in `targetString`, otherwise `false`.
 */
const matchWords: (searchString: string, targetString: string) => boolean = (searchString, targetString) => {
  if (targetString === null || targetString === undefined) {
    console.warn('matchWords: targetString is null or undefined');
    return false;
  }

  // Split the searchString into an array of words
  // using a regex pattern to match any whitespace
  // or punctuation characters as separators
  const words = splitWords(searchString);

  // Create a regex pattern to match any of the words
  // in the array, separated by the OR operator
  const pattern = `(${words.join('|')})`;

  // Create a regex object with the pattern and flags
  // to ignore case and match all occurrences
  const regex = new RegExp(pattern, 'gi');

  // Find all matches of the pattern in the targetString
  const matches = targetString.match(regex);

  // Return true if there are any matches, otherwise false
  // filtering the empty strings
  return !!matches?.length;
};
