<h5 class="title">
  {{ 'Columns' + column?.id | translate }}
  <span class="number"> ({{ column?.therapyJobs.length }}) </span>
</h5>
<div #wrapper class="wrapper">
  @for(therapyJob of column?.therapyJobs; track therapyJob.id){
  <gplus-therapy-job
    [event]="event"
    [id]="therapyJob.id"
    [therapyJob]="therapyJob"
    [tjNotificationMap]="tJNotificationsMap | filterNotification : therapyJob.id"
    [tjHighlightMap]="tjHighlightMap"
    [ticker]="ticker"
    [highlightTerm]="highlightTerm"
    (onChange)="onTherapyJobChange($event)"
  ></gplus-therapy-job>
  }
</div>
