import { Pipe, PipeTransform } from '@angular/core';
import { CarrierSolution } from '../models/generated/carrierSolution';
import { Medicine } from '../models/generated/medicine';
import { PersonNamePipe } from './PersonName';
import { MedicineUnit } from '../models/MedicineUnit';

export type labelType =
  | 'carrier'
  | 'medicine'
  | 'personName'
  | 'unit'
  | 'default'
  | 'patient'
  | 'physician'
  | 'room'
  | 'monitorDevice'
  | 'default';
@Pipe({
  standalone: true,
  name: 'printLabel'
})
export class PrintLabelPipe implements PipeTransform {
  constructor(private readonly personName: PersonNamePipe) {}
  transform(value, type: labelType): string | null {
    if (type === 'default') {
      return value.label;
    }
    if (type === 'carrier') {
      return this.carrierName(value);
    }
    if (type === 'medicine') {
      return this.medicineName(value);
    }
    if (type === 'personName' || type === 'patient' || type === 'physician') {
      return this.displayPersonName(value);
    }

    if (type === 'unit') {
      return this.unitName(value);
    }

    if (type === 'room') {
      return `${value.id}`;
    }

    if (type === 'monitorDevice') {
      return value.label;
    }

    return value;
  }

  private carrierName(carrier: CarrierSolution): string {
    if (!carrier) {
      return '';
    }
    return `${carrier.description}`;
  }

  private medicineName(medicine: Medicine): string {
    if (!medicine) {
      return '';
    }
    return `${medicine.display}`;
  }

  private unitName(unit: MedicineUnit): string {
    if (!unit) {
      return '';
    }
    return `${unit.label}`;
  }

  private displayPersonName(person: { lastName: string; firstName: string }): string {
    if (!person) {
      return '';
    }

    return this.personName.transform(person);
  }
}
