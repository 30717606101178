<cw-menu>
  <div slot="trigger">
    @if(image){
    <img class="user-avatar" [src]="image" alt="profile-picture" />

    } @else {

    <cw-avatar *ngIf="profile" avatar-type="icon" size="md" state="online"></cw-avatar>

    }
  </div>

  <div slot="header" *ngIf="profile">
    <div class="user-info">
      <div>
        <h5 class="user-info-name">{{ profile.firstName }} {{ profile.lastName }}</h5>
        <div class="user-info-email">{{ profile.email }}</div>
      </div>
    </div>
  </div>

  @for( site of sites; track site.id){
  <cw-menu-item
    menu-type="result"
    customShadowCss
    styles=".menu-item:hover{background: inherit !important}.result{padding:0 !important}"
  >
    <div class="user-unit-switch">
      <div class="user-unit-switch-label">
        {{ 'youAreSignedWith' | translate }}
      </div>

      @for(unit of site.units; track unit.id){

      <div
        class="user-unit-switch-item"
        [class.active]="userService?.context()?.unitId === unit.id"
        (click)="selectedContext({ unitId: unit.id, siteId: site.id })"
      >
        {{ unit.name }} - {{ site.name }}
      </div>

      }
    </div>
  </cw-menu-item>
  }

  <cw-menu-item menu-type="result" (click)="logout()">
    <cw-icon filled="{true}" icon="logout"></cw-icon>
    <span>{{ 'LogOut' | translate }}</span>
  </cw-menu-item>
</cw-menu>
