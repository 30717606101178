import { Injectable, signal, Signal, WritableSignal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, map, merge, of } from 'rxjs';
import { NotificationLevel } from '../enums/NotificationLevel';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  private readonly onlineSignal: WritableSignal<boolean> = signal(null);
  public online: Signal<boolean> = this.onlineSignal.asReadonly();

  constructor(private readonly toastService: ToastService, private readonly translateService: TranslateService) {
    merge(
      fromEvent(window, 'online').pipe(map(() => true)),
      fromEvent(window, 'offline').pipe(map(() => false)),
      of(navigator.onLine)
    ).subscribe((isOnline) => {
      console.info('isOnline', isOnline);
      this.onlineSignal.set(isOnline);
      if (!isOnline) {
        this.toastService.show({
          message: this.translateService.instant('ErrorNoInternetConnection'),
          type: NotificationLevel.ERROR,
          enableCloseButton: true,
          delay: null
        });
      } else {
        this.toastService.close();
      }
    });
  }
}
