@if(dataMap){
<form *ngIf="form" class="form" [formGroup]="form" (keydown.enter)="$event.preventDefault()">
  <!-- FILTERS -->
  <cw-button
    [attr.data-vef]="'topBar-filters-btn'"
    [buttonColor]="jobsStore.filter()?.queries?.length ? 'primary' : 'secondary'"
    slot="trigger"
    variant="ghost"
    buttonColor="secondary"
    (click)="toggleFilters()"
  >
    <cw-icon icon="filter_alt" [filled]="jobsStore.filter()?.queries?.length"></cw-icon>
    {{ 'FiltersBtn' | translate }}
    <cw-icon class="menu-trigger" icon="arrow_drop_down" filled="true"></cw-icon>
  </cw-button>

  <div *ngIf="toggleFilter" class="filters">
    <div class="search-bar" (click)="$event.stopPropagation()">
      <gplus-search-bar class="header-search on-white"></gplus-search-bar>
    </div>

    @for(filter of filters; track filter.id){

    <div [attr.data-vef]="'topBar-filters-' + filter.id + '-option'" (click)="$event.stopPropagation()">
      <ng-container *ngTemplateOutlet="filterSwitch; context: { filter }"></ng-container>
    </div>

    }
  </div>

  <!-- QUICK FILTERS -->

  <div class="quick-filters">
    @for(filter of filters; track filter.id){

    <ng-container *ngIf="form.controls[filter.id].dirty">
      <ng-container *ngTemplateOutlet="filterSwitch; context: { filter }"></ng-container>
    </ng-container>

    }
  </div>

  <!--  SWITCH TEMPLATE -->
  <ng-template #filterSwitch let-filter="filter">
    <!-- SWITCH -->
    @switch (filter.control) {
    <!-- CASE -->
    @case (filterControl.SELECTOR) {
    <gplus-dropdown
      [ngClass]="['dropdown', filter.id]"
      [control]="form?.controls?.[filter.id]"
      [data]="dataMap[filter.id]"
      [placeholder]="filter.placeholder | translate"
      [labelType]="filter.id"
      [multiple]="filter.multiple"
    ></gplus-dropdown>

    }

    <!-- CASE -->
    @case (filterControl.DATE) {

    <gplus-datepicker
      mode="single"
      [control]="form.controls[filter.id]"
      [placeholder]="filter.placeholder | translate"
    ></gplus-datepicker>

    }

    <!-- CASE -->
    @case (filterControl.CHECKBOX) {
    <div class="checkbox-wrapper">
      <cw-checkbox-extended [formControlName]="filter.id"
        ><span>{{ filter.label | translate }}</span>
      </cw-checkbox-extended>
      <cw-icon
        *ngIf="form.controls[filter.id].value !== null"
        (click)="removeCheckbox(form.controls[filter.id], $event)"
        class="selector-clear-icon inline"
        icon="close"
        size="sm"
      >
      </cw-icon>
    </div>

    } }
  </ng-template>

  <cw-button
    [attr.data-vef]="'topBar-filters-clear'"
    class="clear-btn"
    *ngIf="jobsStore.filter()?.queries?.length"
    (click)="resetAllFilters()"
    slot="trigger"
    button-color="secondary"
    variant="ghost"
  >
    {{ 'FiltersClearBtn' | translate }}
  </cw-button>
</form>
}
