import { Component, Input } from '@angular/core';
import { CwList, CwListItem } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { take } from 'rxjs';
import { LastContext, Site } from '~/app/models/User';
import { Dialog } from '~/app/services/dialog.service';
import { UserService } from '~/app/services/user.service';

@Component({
  selector: 'gplus-context-modal',
  standalone: true,
  imports: [TranslateModule, CwList, CwListItem],
  templateUrl: './context-modal.component.html',
  styleUrl: './context-modal.component.scss'
})
export class ContextModalComponent {
  public sites: Array<Site>;
  @Input() dialog: Dialog;

  public noWardsAvailable: boolean;

  constructor(private readonly userService: UserService) {
    this.userService
      .getMe()
      .pipe(take(1))
      .subscribe((me) => {
        this.sites = me.sites;
        this.noWardsAvailable = this.sites.some((site) => !!site.units.length);
      });
  }

  public selected(context: LastContext) {
    this.dialog.confirm.next(context);
  }
}
