import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  public getBaseRestUrl(): string {
    return '/api2';
  }
}
