<cw-dialog
  [open]="dialog?.open"
  [dialogTitle]="dialog?.config?.title | translate"
  [actionButtonLabel]="dialog?.config?.actionLabel | translate"
  [secondaryButtonLabel]="dialog?.config?.secondaryLabel | translate"
  action-button-color="accent"
  action-button-variant="filled"
  secondary-button-color="secondary"
  secondary-button-variant="outlined"
  (dialogClosed)="dialog?.config?.secondaryLabel ? dialog?.confirm.next(false) : null"
  (dialogActionButtonClicked)="dialog?.confirm.next(true)"
>
  @if(dialog?.open){ @if(dialog?.component){

  <ng-container *ngComponentOutlet="dialog.component; inputs: { dialog: dialog }"> </ng-container>

  } @if(dialog?.config?.content){
  {{ dialog.config.content | translate }}
  } }
</cw-dialog>
