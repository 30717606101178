import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl } from '@angular/forms';
import { CwInput, CwMenu } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { CustomShadowCssDirective } from '~/app/directives/CustomShadowCss';
import { labelType, PrintLabelPipe } from '~/app/pipes/PrintLabel';
import { change } from '~/app/utils/valueChanges';

@Component({
  selector: 'gplus-autocomplete',
  standalone: true,
  imports: [CwMenu, CwInput, TranslateModule, AsyncPipe, CustomShadowCssDirective, PrintLabelPipe, NgTemplateOutlet],
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [PrintLabelPipe]
})
export class AutocompleteComponent implements OnInit {
  @Input() control: AbstractControl;
  @Input() filteredBy: Array<string>;
  @Input() data: Array<any>;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() attrVef: string;
  @Input() attrVefItem: string;
  @Input() validations: Record<string, any> = {};
  @Input() labelType: labelType;

  public filteredData: Observable<Array<any>>;

  public inputTextValue: string;
  public error: string;
  private destroyRef = inject(DestroyRef);

  constructor(private readonly printLabel: PrintLabelPipe) {}

  ngOnInit(): void {
    if (this.data) {
      this.filteredData = change(this.control, this.data, this.filteredBy).pipe(takeUntilDestroyed(this.destroyRef));
    }
    if (this.control) {
      if (this.control.value) {
        this.inputTextValue = this.getInputTextValue(this.control.value);
      }

      this.control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res) => {
        this.inputTextValue = this.getInputTextValue(res);

        this.error = Object.keys(this.validations).reduce((err, key) => {
          return err || (this.control.hasError(key) ? this.validations[key] : null);
        }, null);
      });
    }
  }
  public selected(inputtedValue: any | CustomEvent) {
    if (!this.control) {
      return;
    }

    this.control.setValue(this.setValue(inputtedValue));
    this.control.markAsDirty();
  }

  private setValue(inputtedValue: any | CustomEvent) {
    if (inputtedValue instanceof CustomEvent) {
      return inputtedValue.detail as string;
    } else {
      return inputtedValue;
    }
  }
  private getInputTextValue(value: Record<string, any> | string): string {
    return this.printLabel.transform(value, typeof value === 'string' ? 'default' : this.labelType);
  }
}
