import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CwButton, CwIcon, CwMenu, CwMenuItem } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { NetworkService } from '~/app/services/network.service';
import { WsEventsService } from '~/app/services/websocket.service';
import { FiltersComponent } from '../filters/filters.component';

@Component({
  selector: 'gplus-top-bar',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule, CwButton, CwIcon, CwMenu, CwMenuItem, FiltersComponent],
  templateUrl: './top-bar.component.html',
  styleUrl: './top-bar.component.scss'
})
export class TopBarComponent {
  constructor(public readonly ws: WsEventsService, public network: NetworkService) {}
}
