import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Toast, ToastService } from '~/app/services/toast.service';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Component({
  selector: 'gplus-toast-container',
  standalone: true,
  imports: [CommonModule, SnackbarComponent],
  templateUrl: './toast-container.component.html',
  styleUrl: './toast-container.component.scss',
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ transform: `translate(100%, 0)` }),
        animate('200ms ease-in-out', style({ transform: `translate(0, 0)` }))
      ]),
      transition(':leave', [
        style({ transform: `translate(0, 0)` }),
        animate('300ms', style({ transform: `translate(100%, 0)` }))
      ])
    ])
  ]
})
export class ToastContainerComponent implements OnChanges {
  private timeoutId;
  @Input() toast: Toast;

  public iconsMap = { error: 'error', warning: 'warning', info: 'info', success: 'check_circle' };

  constructor(private readonly toastService: ToastService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.toast && this.toast?.delay) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      this.timeoutId = setTimeout(() => {
        this.close();
      }, this.toast.delay);
    }
  }

  public close(): void {
    this.toastService.close();
  }
}
