import { DEFAULT_COLUMNS } from '../constants/defaultColumns';
import { TherapyJobStatus } from '../enums/TherapyJobStatus';
import { TherapyJob } from '../models/TherapyJob';
import { Column } from '../models/ui/Column';
import { ProgressPipe } from '../pipes/Progress';
import { NotificationsStore } from '../store/notification.store';

const progressPipe = new ProgressPipe();

const compare = (a: TherapyJob, b: TherapyJob, getLevel: (id: string) => number) => {
  switch (a.status) {
    case TherapyJobStatus.PRESCRIBED:
      if (a.startTime.planned !== b.startTime.planned) {
        return a.startTime.planned - b.startTime.planned;
      } else {
        return a.patient.lastName.localeCompare(b.patient.lastName);
      }
    case TherapyJobStatus.RUNNING:
      const aLevel = getLevel(a.id) || Number.MAX_SAFE_INTEGER;
      const bLevel = getLevel(b.id) || Number.MAX_SAFE_INTEGER;

      if (aLevel !== bLevel) {
        return aLevel - bLevel;
      } else if (a?.latestEvent?.injectedVolume !== b?.latestEvent?.injectedVolume) {
        return (
          progressPipe.transform(b?.latestEvent?.injectedVolume, b?.totalVolume) -
          progressPipe.transform(a?.latestEvent?.injectedVolume, a?.totalVolume)
        );
      } else {
        return a.patient.lastName.localeCompare(b.patient.lastName);
      }

    case TherapyJobStatus.COMPLETED:
      // until we can test different completion cases we just sort by name
      return a.patient.lastName.localeCompare(b.patient.lastName);

    default:
      return a.patient.lastName.localeCompare(b.patient.lastName);
  }
};

export const getColumns: (therapyJobs: Array<TherapyJob>, notificationStore: NotificationsStore) => Array<Column> = (
  therapyJobs,
  notificationStore
) => {
  console.log('getColumns');
  return DEFAULT_COLUMNS.map((c) => ({
    id: c,
    therapyJobs:
      therapyJobs
        ?.filter((tj) => tj.status === c)
        .toSorted((a, b) => {
          return compare(a, b, notificationStore.getNotificationLevelById);
        }) || []
  }));
};
