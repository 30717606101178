import { CommonModule } from '@angular/common';
import { Component, HostListener, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CwButton, CwIcon } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, debounceTime, map, Observable, take } from 'rxjs';
import { EmployeeType } from '~/app/enums/EmployeeType';
import { Filter } from '~/app/models/Filter';
import { PersonNamePipe } from '~/app/pipes/PersonName';
import { CarrierService } from '~/app/services/carrier.service';
import { EmployeeService } from '~/app/services/employee.service';
import { MedicineService } from '~/app/services/medicine.service';
import { PatientService } from '~/app/services/patient.service';
import { TherapyJobStore } from '~/app/store/job.store';
import { CheckboxExtendedComponent } from '~/app/utils/CheckboxExtended';
import { groupGenerator } from '~/app/utils/FormGroupGenerator';
import { DatepickerComponent } from '../datepicker/datepicker.component';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { FilterConfig, FilterControl, filtersConfig } from './filters-config';

@Component({
  selector: 'gplus-filters',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CheckboxExtendedComponent,
    CwButton,
    CwIcon,
    TranslateModule,
    SearchBarComponent,
    DropdownComponent,
    DatepickerComponent
  ],
  templateUrl: './filters.component.html',
  styleUrl: './filters.component.scss',
  providers: [PersonNamePipe]
})
export class FiltersComponent {
  public filterControl = FilterControl;
  public filters: Array<FilterConfig> = filtersConfig.filter((f) => f.show);
  public toggleFilter: boolean = false;

  public form: FormGroup;
  public jobsStore = inject(TherapyJobStore);
  public dataMap: Record<string, unknown>;

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const topParent = target.parentElement;

    // if the parent is not form, then we close the filters panel
    if (this.toggleFilter && !topParent?.classList.contains('form')) {
      this.toggleFilter = false;
    }
  }

  constructor(
    private readonly patientService: PatientService,
    public readonly carrierService: CarrierService,
    private readonly medicineService: MedicineService,
    private readonly employeeService: EmployeeService
  ) {
    this.form = groupGenerator(this.filters);

    // We subscribe to the changes in the filters
    this.form.valueChanges.pipe(takeUntilDestroyed(), debounceTime(300)).subscribe((change) => {
      this.jobsStore.updateFilter(this.convertToFilter(change));
    });

    this.getData().subscribe((dataMap) => {
      this.dataMap = dataMap;
      this.patchValues();
    });
  }

  getData(): Observable<Record<string, unknown>> {
    return combineLatest([
      this.employeeService.getEmployees(EmployeeType.PHYSICIAN),
      this.patientService.getPatients(),
      this.medicineService.getMedicines(),
      this.carrierService.getCarriers()
    ]).pipe(
      take(1),
      map(([physician, patient, medicine, carrier]) => {
        return {
          physician,
          patient,
          medicine,
          carrier,
          // for now we do not get the rooms from backend
          // we extract the rooms from the patients
          room: Array.from(new Set(patient.map((p) => p.careUnit.room)))
            .map((r) => ({ id: r }))
            .sort((a, b) => a.id.localeCompare(b.id))
        };
      })
    );
  }

  private patchValues(): void {
    const filterValuesMap = this.jobsStore.filter().queries.reduce((map, f) => {
      map[f.on] = f.value;
      return map;
    }, {});

    this.form.patchValue(filterValuesMap);

    // We mark as dirty the controls that were patched so we can show them outside the panel
    Object.keys(filterValuesMap).forEach((key) => {
      this.form.get(key).markAsDirty();
    });
  }

  private convertToFilter(formValues): Filter {
    const newQueries = Object.keys(formValues).reduce((acc, key) => {
      const filterConfig = this.filters.find((f) => f.id === key);

      if (formValues[key] === null) {
        return acc;
      }

      const bindValueKey = filterConfig.bindValue;

      if (!bindValueKey || typeof formValues[key] === 'string' || typeof formValues[key] === 'number') {
        return acc.concat([
          {
            on: key,
            bindValue: formValues[key],
            value: formValues[key]
          }
        ]);
      }

      let bindValue;
      if (Array.isArray(formValues[key])) {
        bindValue = formValues[key]?.map((d) => d[filterConfig.bindValue]);
      } else {
        bindValue = formValues[key][filterConfig.bindValue];
      }

      return acc.concat([
        {
          on: key,
          bindValue,
          value: formValues[key]
        }
      ]);
    }, []);

    const searchQuery = this.jobsStore.filter().queries.find((q) => q.on === 'search');

    return {
      queries: searchQuery ? newQueries.concat(searchQuery) : newQueries
    };
  }

  public toggleFilters() {
    this.toggleFilter = !this.toggleFilter;
  }

  public resetAllFilters() {
    this.form.reset();
    this.jobsStore.updateFilter({ queries: [] });
    this.form.markAsPristine();
  }

  public removeCheckbox(control: AbstractControl, event: MouseEvent) {
    event.stopPropagation();
    control.reset();
    control.markAsPristine();
  }
}
