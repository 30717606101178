import { Injectable, signal, WritableSignal } from '@angular/core';
import { Subject } from 'rxjs';
import { ContextModalComponent } from '../components/context-modal/context-modal.component';

export enum DialogIds {
  LEAVE_WITH_UNSAVED = 'leaveWithUnsaved',
  CONTEXT_SELECTOR = 'contextSelector'
}

export interface Dialog {
  open: boolean;
  id: DialogIds;
  config: {
    title: string;
    actionLabel: string;
    secondaryLabel: string;
    content: string;
  };
  component: any;
  confirm: Subject<boolean | any>;
}

const dialogs: Array<Dialog> = [
  {
    open: false,
    id: DialogIds.LEAVE_WITH_UNSAVED,
    config: {
      title: 'DialogUnsavedChangesTitle',
      actionLabel: 'DialogUnsavedChangesActionLabel',
      secondaryLabel: 'DialogUnsavedChangesCancelLabel',
      content: 'DialogUnsavedChangesContent'
    },
    confirm: new Subject(),
    component: null
  },
  {
    open: false,
    id: DialogIds.CONTEXT_SELECTOR,
    config: {
      title: 'WardSelectorModalTitle',
      actionLabel: null,
      secondaryLabel: null,
      content: null
    },
    confirm: new Subject(),
    component: ContextModalComponent
  }
];

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private dialogSignal: WritableSignal<Dialog> = signal(null);
  public dialog = this.dialogSignal.asReadonly();

  public open(id: DialogIds): void {
    const dialog = dialogs.find((d) => d.id === id);
    this.dialogSignal.update(() => ({ ...dialog, open: true }));
  }

  public close() {
    this.dialogSignal.update((current) => ({ ...current, open: false }));
  }
}
