<div *ngIf="therapyJob" class="wrapper" [ngClass]="mostCriticalStatus" [id]="therapyJob.id" (click)="openDetail()">
  <div class="header">
    <h4 class="name">
      <span
        [attr.data-vef]="'card-patientName'"
        *ngIf="therapyJob?.patient"
        [innerHtml]="therapyJob?.patient | personName | highlight : highlightTerm"
      >
      </span>
      <div class="id">{{ 'TherapyJobIdLabel' | translate }}: {{ therapyJob.id }}</div>
    </h4>
    <div class="header-left">
      <div class="badges">
        @if(therapyJob.prepared && therapyJob.status === therapyStatus.PRESCRIBED){

        <cw-badge-status [attr.data-vef]="'card-prepared-badge'" type="secondary" status="success">{{
          'StatusPrepared' | translate
        }}</cw-badge-status>
        } @if(mostCriticalStatus){
        <cw-badge-status [status]="mostCriticalStatus">
          <cw-icon class="icon" icon="location_on" size="xs"></cw-icon> {{ therapyJob.patient?.careUnit?.room }} -
          {{ therapyJob.patient?.careUnit?.bed }}
        </cw-badge-status>

        } @else {

        <div [attr.data-vef]="'card-careUnit'" class="care-unit">
          <cw-icon class="icon" icon="location_on" size="xs"></cw-icon> {{ therapyJob.patient?.careUnit?.room }} -
          {{ therapyJob.patient?.careUnit?.bed }}
        </div>

        }
      </div>

      <cw-button
        class="menu"
        slot="trigger"
        rounded="true"
        button-color="secondary"
        [disabled]="disableMenu"
        (click)="toggleMenu($event)"
        [attr.data-vef]="'card-menu-toggle'"
      >
        <cw-icon class="menu-trigger icon" icon="more_vert" filled="true"></cw-icon>
      </cw-button>
    </div>
  </div>

  <div class="body">
    <div class="group b1 medicine">
      <cw-icon class="icon" icon="medication" size="sm"></cw-icon>
      <span [attr.data-vef]="'card-medicine'" [innerHTML]="medicine?.first | highlight : highlightTerm"> </span>
      <span class="plus-more" *ngIf="medicine?.more">{{ medicine.more }}</span>
    </div>
    <div class="group b1 device" [class.disabled]="therapyJob.pairingState !== pairingStatus.PAIRED">
      <gplus-icon-device [class.disabled]="therapyJob.pairingState !== pairingStatus.PAIRED"></gplus-icon-device>
      <span [attr.data-vef]="'card-deviceId-label'">{{
        therapyJob.pairingState === pairingStatus.PAIRED ? therapyJob.monitorDeviceId : '-'
      }}</span>
    </div>
    <div class="group b1 flow-rate" [class.disabled]="!flowRate">
      <cw-icon class="icon" icon="speed" size="sm"></cw-icon>
      <span>{{ flowRate || '-' }}</span>
    </div>
    <div class="group b1 time" [class.disabled]="!time">
      <cw-icon class="icon" icon="alarm" size="sm"></cw-icon>

      <span class="time-label">
        <span [attr.data-vef]="'card-time'">{{ time || '-' }} </span
        ><span class="error-value" [attr.data-vef]="'card-delayedTime-label'">{{ timeError }}</span></span
      >
    </div>
  </div>

  <div class="footer" [class.has-annotations]="therapyJob.annotations && !showAnnotations">
    <!-- Annotations -->
    <gplus-annotations
      class="annotations"
      [class.show]="showAnnotations"
      [value]="therapyJob.annotations"
      [showAnnotations]="showAnnotations"
      (onSubmit)="onSubmitAnnotations($event)"
      (toggle)="toggleAnnotations($event)"
    ></gplus-annotations>

    <!-- Progress bar -->
    @if(therapyJob.status === therapyStatus.RUNNING && !tjNotificationMap?.[notificationType.DRIP_CHAMBER_DETACHED + '-'
    + therapyJob?.id] && !tjNotificationMap?.[notificationType.THERAPY_JOB_UNPAIRED_FROM_MD + '-' + therapyJob?.id]){

    <gplus-infusion size="sm" [level]="mostCriticalStatus" [therapyJob]="therapyJob"></gplus-infusion>
    }

    <!-- Move to history button -->
    <cw-button
      *ngIf="therapyJob.status === therapyStatus.COMPLETED"
      button-color="secondary"
      variant="outlined"
      type="button"
      as="button"
      [disabled]="true"
      (click)="finishJob()"
    >
      <cw-icon class="icon" icon="description"></cw-icon>
      {{ 'TherapyJobFinishJobBtn' | translate }}
    </cw-button>

    <!-- Monitor device -->
    <gplus-manage-device class="connect-selector" [therapyJob]="therapyJob" [event]="event"></gplus-manage-device>
  </div>

  <div class="notifications" *ngIf="tjNotificationMap">
    <div class="notification" *ngFor="let item of tjNotificationMap | keyvalue">
      <cw-icon class="icon" icon="warning" [filled]="true"></cw-icon>
      {{ item.value.translationKey | translate }}
    </div>
  </div>

  <!-- Missing information -->
  <div *ngIf="missingInfo" class="missing-info">
    <div class="missing-info-inner">
      <cw-icon class="icon" icon="info" [filled]="true"></cw-icon>
      {{ missingInfo.tooltip | translate }}
    </div>
  </div>
</div>
