import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, signal, WritableSignal } from '@angular/core';
import { map, Observable, of, shareReplay, switchMap, take, tap } from 'rxjs';
import { environment } from '~/environments/environment';
import { LastContext, User } from '../models/User';
import { DialogIds, DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private me: Observable<User>;

  private contextSignal: WritableSignal<LastContext> = signal(null);
  public context = this.contextSignal.asReadonly();
  private contextReplay: Observable<LastContext>;

  constructor(private http: HttpClient, private readonly dialogService: DialogService) {}
  public getMe(): Observable<User> {
    if (!this.me) {
      this.me = this.http.get<User>(`${environment.gprApiConfig.uri}me`).pipe(shareReplay());
    }

    return this.me;
  }

  public getContext(): Observable<LastContext> {
    if (!this.contextReplay) {
      this.contextReplay = this.getMe().pipe(
        switchMap((me) => {
          if (me.lastContext?.siteId && me.lastContext?.unitId) {
            // If the user has a context, return it
            this.contextSignal.set(me.lastContext);

            return of(this.contextSignal());

            // If the user has only one site and one unit, set the context to that
          } else if (me.sites.length === 1 && me.sites[0].units.length === 1) {
            return this.setContext({
              siteId: me.sites[0].id,
              unitId: me.sites[0].units[0].id
            }).pipe(map(() => this.contextSignal()));
          } else {
            this.dialogService.open(DialogIds.CONTEXT_SELECTOR);

            return this.dialogService.dialog().confirm.pipe(
              take(1),
              switchMap((context: LastContext) => {
                return this.setContext(context).pipe(
                  map(() => {
                    this.dialogService.close();

                    return this.contextSignal();
                  })
                );
              })
            );
          }
        }),
        shareReplay()
      );
    }
    return this.contextReplay;
  }

  public setContext(context: LastContext): Observable<any> {
    const params = new HttpParams().set('UnitId', context.unitId);
    return this.http
      .post(`${environment.gprApiConfig.uri}me/lastcontext`, null, { params })
      .pipe(tap(() => this.contextSignal.set(context)));
  }
}
