import { Injectable, WritableSignal, signal } from '@angular/core';
import { NotificationLevel } from '../enums/NotificationLevel';

export interface Toast {
  type: NotificationLevel;
  message: string;
  delay: number;
  open?: boolean;
  enableCloseButton?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private toastSignal: WritableSignal<Toast> = signal(null);
  public toast = this.toastSignal.asReadonly();

  public show(toast: Toast): void {
    this.toastSignal.set({ ...toast, open: true });
  }

  public close(): void {
    this.toastSignal.set(null);
  }
}
