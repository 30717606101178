import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, map, of } from 'rxjs';
import { CarrierSolution } from '../models/generated/carrierSolution';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class CarrierService {
  private carriers: Array<CarrierSolution>;
  constructor(
    private readonly httpClient: HttpClient,
    private readonly env: EnvironmentService,
    private readonly translate: TranslateService
  ) {}

  public getCarriers(): Observable<Array<CarrierSolution>> {
    return this.httpClient.get<Array<CarrierSolution>>(`${this.env.getBaseRestUrl()}/carriersolution`).pipe(
      catchError((err) => {
        console.warn('Error requesting carriers', err);
        return of([]);
      }),
      map((carriers) => {
        this.carriers = [
          {
            description: this.translate.instant('FormTJCreationReadyToAdminister')
          },
          ...carriers
        ];

        return this.carriers;
      })
    );
  }
}
