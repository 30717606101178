import { CommonModule } from '@angular/common';
import { Component, Signal, computed } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CwChip } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { interval } from 'rxjs';
import { CustomShadowCssDirective } from '~/app/directives/CustomShadowCss';
import { TherapyJobStatus } from '~/app/enums/TherapyJobStatus';
import { TherapyJob } from '~/app/models/TherapyJob';
import { HighlightService } from '~/app/services/highlight.service';
import { WsEventsService } from '~/app/services/websocket.service';
import { EventManager } from '~/app/utils/EventManager';
import { getColumns } from '~/app/utils/getColumns';
import { Column } from '../../models/ui/Column';
import { TherapyJobService } from '../../services/therapy-job.service';
import { BoardColumnComponent } from '../board-column/board-column.component';

@Component({
  selector: 'gplus-board',
  standalone: true,
  imports: [
    // Modules
    CommonModule,
    TranslateModule,
    CwChip,
    // Components
    BoardColumnComponent,
    CustomShadowCssDirective
  ],
  templateUrl: './board.component.html',
  styleUrl: './board.component.scss'
})
export class BoardComponent extends EventManager {
  public columns: Signal<Array<Column>>;
  public ticker: number;
  public highlightTerm: Signal<string>;

  // Only for responsive
  public currentColumn: string = TherapyJobStatus.PRESCRIBED;

  constructor(
    public readonly highlightService: HighlightService,
    private readonly therapyJobService: TherapyJobService,
    protected override ws: WsEventsService
  ) {
    super(ws);
    interval(5000)
      .pipe(takeUntilDestroyed())
      .subscribe((res) => {
        this.ticker = res;
      });

    this.columns = computed(() => getColumns(this.jobsStore.filtered(), this.notificationStore));
    this.highlightTerm = computed(() => this.jobsStore.filter().queries.find((q) => q.on === 'search')?.bindValue);
  }

  public selectChip(columnId: string) {
    this.currentColumn = columnId;
  }

  public onTherapyJobChange(change: { value: Partial<TherapyJob>; id: string }) {
    this.jobsStore.patchJob(change.value, change.id);

    this.therapyJobService.updateTherapyJob(change.value, change.id).subscribe((res) => {
      if ((res as { error: string })?.error) {
        // something went wrong we reload
        this.jobsStore.loadAll().then(() => this.notificationStore.setAll());
      } else {
        console.info('Therapy Job successfully edited');
      }
    });
  }
}
